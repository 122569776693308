import { Button, Center, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select } from "@chakra-ui/react"
import { UserInterface } from "./interfaces/user.interface"
import * as Yup from 'yup'
import { Field, Form, Formik } from "formik"
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { updateUser } from "../../services/api"

type PropsType = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    user: UserInterface | null
    reload: () => void
}

enum UserTypeEnum {
    CUSTOMER = 'CLIENTE',
    ADMIN = 'ADMINISTRADOR',
    ORGANIZER = 'ORGANIZADOR',
}

export const EditUserModal = (props: PropsType) => {

    const {
        isOpen,
        setIsOpen,
        user,
        reload
    } = props

    const validationSchema = Yup.object({
        name: Yup.string().required('O nome é obrigatório'),
        email: Yup.string().email('Email inválido').required('O email é obrigatório'),
        password: Yup.string()
        .when('updatePassword', {
            is: true, // só valida quando a atualização da senha é verdadeira
            then: Yup.string()
                .required('A senha é obrigatória')
                .min(6, 'A senha deve ter pelo menos 6 caracteres'), // validação de mínimo de 6 caracteres
            otherwise: Yup.string().nullable(), // se não atualizar a senha, permite nulo
        }),
        //document_number: Yup.string().required('O cpf é obrigatório').length(11, 'O cpf deve ter exatamente 11 dígitos'),
        document_number: Yup.string()
            .required('O cpf é obrigatório')
            .length(11, 'O cpf deve ter exatamente 11 dígitos')
            .matches(/^\d+$/, 'O CPF deve conter apenas números'),
        area_code: Yup.string().required('O ddd é obrigatório').length(2, 'O ddd deve ter exatamente 2 dígitos'),
        phone_number: Yup.string().required('O telefone é obrigatório').length(9, 'O telefone deve ter exatamente 9 dígitos'),
        user_type: Yup.mixed().oneOf(Object.keys(UserTypeEnum)).required('O tipo de usuário é obrigatório'),
        updatePassword: Yup.boolean().required(),
    })

    const initialValues = {
        name: user?.name || '',
        email: user?.email || '',
        password: null,
        document_number: user?.cpf || '',
        area_code: user?.phone.substring(0, 2) || '',
        phone_number: user?.phone.substring(2, 11) || '',
        user_type: user?.user_type,
        updatePassword: false,
    }

    const onSubmit = async (values: typeof initialValues) => {
        const { updatePassword, ...otherValues } = values;
        const payload = updatePassword ? values : otherValues;
        const newPayload = {
            ...payload, 
            document_number: `${payload.document_number}`, 
            area_code: `${payload.area_code}`, 
            phone_number: `${payload.phone_number}`
        };

        try {
            await updateUser(user!.id, newPayload)
            toast.success("Usuário atualizado com sucesso")
            onClose();
            reload()
        
        } catch (error: any) {

            if(error.response.data.message.includes('duplicar valor da chave viola a restrição de unicidade')){
                toast.error("Email ou cpf já está em uso!")

            }else{
                toast.error("Erro ao atulizar usuário!")
            }
        }
    }

    const onClose = () => setIsOpen(false);

    return(
        <Modal
            size="2xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center><h1>INFORMAÇÕES DO USUÁRIO</h1></Center> 
                </ModalHeader>
                
                <ModalCloseButton />
                    <ModalBody pb={6}>
                
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        >
                            {({errors, touched, values, setFieldValue }) => (
                                <Form>
                                    <FormControl isInvalid={!!errors.name && touched.name} mb={4}>
                                        <FormLabel htmlFor="name">Nome</FormLabel>
                                        <Field as={Input} id="name" name="name" placeholder="Nome" />
                                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.email && touched.email} mb={4}>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <Field as={Input} id="email" name="email" placeholder="Email" />
                                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.updatePassword && touched.updatePassword} mb={4}>
                                        <FormLabel htmlFor="updatePassword">Atualizar Senha?</FormLabel>
                                        <Field as={Select} id="updatePassword" name="updatePassword" onChange={(e: any) => setFieldValue('updatePassword', e.target.value === 'true')}>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </Field>
                                        <FormErrorMessage>{errors.updatePassword}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.password && touched.password} mb={4} isDisabled={!values.updatePassword}>
                                        <FormLabel htmlFor="password">Senha</FormLabel>
                                        <Field as={Input} id="password" name="password" placeholder="Digite a nova senha" type="password" disabled={!values.updatePassword} />
                                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.document_number && touched.document_number} mb={4}>
                                        <FormLabel htmlFor="document_number">CPF</FormLabel>
                                        <Field 
                                            as={Input} 
                                            type="text" 
                                            id="document_number" 
                                            name="document_number" 
                                            placeholder="CPF" 
                                            maxLength={11} 
                                            inputMode="numeric" // Orienta o navegador a usar um teclado numérico
                                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
                                                setFieldValue('document_number', value); // Atualiza o valor do campo
                                            }}
                                        />
                                        <FormErrorMessage>{errors.document_number}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.area_code && touched.area_code} mb={4}>
                                        <FormLabel htmlFor="area_code">DDD</FormLabel>
                                        <Field as={Input} type='number' id="area_code" name="area_code" placeholder="DDD" />
                                        <FormErrorMessage>{errors.area_code}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.phone_number && touched.phone_number} mb={4}>
                                        <FormLabel htmlFor="phone_number">Telefone</FormLabel>
                                        <Field as={Input} type='number' id="phone_number" name="phone_number" placeholder="Telefone" />
                                        <FormErrorMessage>{errors.phone_number}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={!!errors.user_type && touched.user_type} mb={4}>
                                        <FormLabel htmlFor="user_type">Tipo de usuário</FormLabel>
                                        <Field disabled={true} as={Input} id="user_type" name="user_type" placeholder="Tipo de usuário" />
                                        <FormErrorMessage>{errors.user_type}</FormErrorMessage>
                                    </FormControl>

                                    <Button mt={4} colorScheme="teal" type="submit">
                                        Salvar
                                    </Button>

                                </Form>
                            )}
                        </Formik>   

                    </ModalBody>
            </ModalContent>

            <ToastContainer />
        </Modal>
    )
}